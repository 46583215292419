import store from "@/store";
import { fetchExistingAccountData, accountDetailResponse, setAccountDetailResponse, updateAccountDetailResponse, autoSaveAccountDetails } from "@/services/account/account";

import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from "vuex-module-decorators";


@Module({
  namespaced: true,
  name: "storeInterceptor",
  store,
  dynamic: true
})

class storeInterceptorModule extends VuexModule {

  public objAccountData: any = {};

  get newAccountData(){
    return this.objAccountData;
  }
  @Mutation
  public handleAccountData(payload:any) {
    this.objAccountData = payload.data
  }

  @Action({commit: "handleAccountData"})
  public getAccountData(strId:string):Promise<Object>{
    let resultNew = async function fetchAccountData(strId:string): Promise<Object> {
      let promise = accountDetailResponse(strId);
      let result = await promise;  
      return result as object;
    }
    return resultNew(strId);
  }

  @Action({commit: "handleAccountData"})
  public saveAccountData(accountData:any):Promise<Object>{
    let resultNew = async function saveAcData(accountData:any): Promise<Object> {
      let promise = setAccountDetailResponse(accountData);
      let result = await promise;  
      return result as object;
    }
    return resultNew(accountData);
  }


  @Action({commit: "handleAccountData"})
  public updateAccountData(accountData:any):Promise<Object>{
    let resultNew = async function saveAcData(accountData:any): Promise<Object> {
      let promise = updateAccountDetailResponse(accountData);
      let result = await promise;  
      return result as object;
    }
    return resultNew(accountData);
  }


  @Action({commit: "handleAccountData"})
  public autoSaveAccountData(accountData:any):Promise<Object>{
    let resultNew = async function saveAcData(accountData:any): Promise<Object> {
      let promise = autoSaveAccountDetails(accountData);
      let result = await promise;  
      return result as object;
    }
    return resultNew(accountData);
  }

  @Action({commit: "handleAccountData"})
  public fetchExistingAccountDetails(strId:string):Promise<Object>{
    let resultNew = async function fetchAccountData(strId:string): Promise<Object> {
      let promise = fetchExistingAccountData(strId);
      let result = await promise;  
      return result as object;
    }
    return resultNew(strId);
  }

  
}

export default getModule(storeInterceptorModule);
