import {Vue, Component, Watch} from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import COMMON_CONST from '@/constants/CommonConst';
import { account_details } from "@/Model/model";
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from "@/utilities/commonFunctions";
import storeInterceptorModule from '@/store/modules/storeInterceptor';
import { AccountDetails } from '@/Model/model.js';
import UIkit from "uikit";
import { getModule } from 'vuex-module-decorators';
import { GlobalModule } from '@/store/global/globalModule';
import store from '@/store';
import InvitationSentComponent from '@/popupcomponents/invitationcomponent/InvitationComponent.vue';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
import { IPanorama, ISublicense, IAccountType } from '@/Model/programModel';
import { getPanoramaSKU, getSublicenseByPanorama, getAccountType } from '@/services/account/account';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import CancelConfirmation from '@/popupcomponents/cancelConfirmation/CancelConfirmation.vue';
import AddAccountSuccess from '@/popupcomponents/addAccountSuccess/AddAccountSuccess.vue';
import BreadcrumbComponent from '@/commoncomponents/breadcrumbComponent/BreadcrumbComponent.vue';
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue';
import programListStore, {ProgramListModule} from "@/store/modules/programList";
import CmsMultiSelectDropdown from '@/commoncomponents/CmsMultiSelectDropdown/CmsMultiSelectDropdown.vue';
import { getAllSubscriptionCollections } from '@/utilities/cms/subscriptionCategory';
import DirectAccessBox from '@/components/newaccount/DirectAccessBox/DirectAccessBox.vue';
import ChangeLearningExperienceModal
    from '@/popupcomponents/changeLearningExperienceModal/ChangeLearningExperienceModal.vue';
import Checkbox from '@/commoncomponents/Checkbox.vue';

@Component({
    components: {
    Checkbox,
        ChangeLearningExperienceModal,
        DirectAccessBox,
        'invitation-sent': InvitationSentComponent,
        'datepicker': DatepickerComponent,
        'progressButton': ProgressButton,
        'cancel-confirmation-popup': CancelConfirmation,
        'success-popup': AddAccountSuccess,
        'bread-crumb': BreadcrumbComponent,
        'dropdown-component': DropdownList,
        'cms-multiselect-dropdown': CmsMultiSelectDropdown,
    }
})
export default class NewAccount extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    private invalidElements: Array<HTMLInputElement> = [];
    public highestRole: number = APP_CONST.MINUS_ONE;
    private isEdited: boolean = false;
    private isDataCanAutoSave: boolean = false;
    private cities: string[] = APP_UTILITIES.fetchCities(APP_CONST.COUNTRY_NAME);
    private states: any = APP_UTILITIES.getStatesShort(APP_CONST.COUNTRY_ID);
    private removedStates: any = APP_CONST.REMOVED_STATES;
    private itemToIgnore = ['accountAdminId', "isFinalSubmit", "description", "isDummy", "status", "roleId", "accId", 'bxPartnerLeadFirstName', 'bxPartnerLeadLastName', 'bxSuccessLeadFirstName', 'bxSuccessLeadLastName','bxPartnerLeadEmail','bxSuccessLeadEmail'];
    private notRequired = ['streetAddressLine2', 'phoneNumber', "id", "personalInvitationNote", "description", "status",'isDirectAccess', 'singleSignOnEnabled', 'automatedProvisioningEnabled'];
    private numberOnlyField: string[] = ['zipCode', 'numberOfScholars'];
    private stringOnlyField: string[] = ['city', 'streetAddressLine1', 'name', 'state', 'programAdmin', 'firstName', 'lastName', 'bxPartnerLeadFirstName', 'bxPartnerLeadLastName', 'bxSuccessLeadFirstName', 'bxSuccessLeadLastName'];
    private emailOnlyField: string[] = ['email'];
    private salesforceIdOnlyField: string[] = ['salesforceAccountId'];
    private requiredKeyForProgram = ['bxPartnerLeadFirstName', 'bxPartnerLeadLastName', 'bxSuccessLeadFirstName', 'bxSuccessLeadLastName'];
    private requiredKeyForSubscription = ['startDate', 'startDate', 'contractedScholars'];
    private autoSaveData: any = {};
    private isEditMode: boolean = false;
    private isDataCanSaveGlobal: boolean = true;
    private isPhoneCanSave: boolean = true;
    private isSaveandContinueLater: boolean = false;
    public accountDt: AccountDetails = {} as AccountDetails;
    private getGlobalState = getModule(GlobalModule, store);
    public dateIsGreater: boolean = false;
    public zipcodeRequired: boolean = false;
    public invalidZipcode: boolean = false;
    public duplicateEmail: boolean = false;
    public duplicateEmailMsg: any = '';
    public checkForCorrectEmail: boolean = true;
    public buttonState: boolean = false;
    public checkForCorrectEmailMSG: any = '';
    public openDropPanorama: boolean = false;
    public panoramas: IPanorama[] = [] as IPanorama[];
    public sublicenses: ISublicense[] = [] as ISublicense[];
    private ignoreItems = ['id', 'status'];
    private invalidCity: boolean = false;
    licensePanoramaSku = ''
    licensesublicenseSku = ''
    private invalidSalesforceId: boolean = false;
    private salesforceIdLength: boolean = false;
    private salesforceIdMinLength: any = APP_CONST.SALESFORCE_ID_MIN_LENGTH;
    private salesforceIdMaxLength: any = APP_CONST.SALESFORCE_ID_MAX_LENGTH;
    apiResponseCode: number = 0
    isValidate: boolean = true;
    private accoutTypesList: IAccountType[] = [] as IAccountType[];
    private type: string = "";
    public breadcrumbs: any = {newAccBreadcrumbs: ['Partner Accounts', 'Account'], editAccBreadcrumbs: ['Partner Accounts', 'Account', 'Edit Account']}
    private initialAccountData:any = {}
    private initialScheduleDate:any = ''
    public accountTypeDropdown : any= {showSelectLabel: true, label: '', id: 0, value: 'Select', required: true, disable: false, search : false, error: false, placeholder : 'Search', dropdownList: [] as any[]};
    public statesDropdown : any= {showSelectLabel: true, label: '', id: 0, value: 'Select', required: true, disable: false, search : false, error: false, placeholder : 'Search', dropdownList: [] as any[]};
    public phoneTypeDropdown : any = {showSelectLabel: true, label: '', id: 0, value: 'Select', required: false, disable: false, search : false, error: false, placeholder : 'Search', dropdownList: [{id: 1, value: 'Cell'}, {id: 2, value: 'Work'}, {id: 3, value: 'Home'}]};
    public panoramaDropdown : any = {showSelectLabel: true, firstSelectLabel: 'Select Panorama', label: '', id: 0, value: 'Select Panorama', required: true, disable: false, search : false, error: false, placeholder : 'Search', dropdownList: [] as any[]};
    public contentSubscriptionDropdown : any = {title: 'Package', singleSelect: false, showSelectLabel: true, firstSelectLabel: 'Select Package', label: '', id: 0, value: 'Select Panorama', required: true, disable: false, search : false, error: false, placeholder : 'Search', dropdownList: [] as any[], options: []};
    public sublicenseDropdown : any = {showSelectLabel: true, firstSelectLabel: 'Select Sublicense', label: '', id: 0, value: 'Select Sublicense', required: true, disable: false, search : false, error: false, placeholder : 'Search', dropdownList: [] as any[]};
    public singleSignOnEnabled = false;

    /* Direct Access */
    public directAccessEnabled: boolean = false;
    private directAccessValidated: boolean = false;
    private showChangeLXModal: boolean = false;
    private shouldRedirectToPrograms: boolean = false;
    handleDirectAccessChange(value: boolean) {
      this.accountData.isDirectAccess.value = value;
    }

    private validateDirectAccess() {
      if (!this.isEditMode) {
        if (this.accountData.isDirectAccess.value) {
          this.showChangeLXModal = true;
        } else {
          this.directAccessValidated = true;
          this.validateForm();
        }
      } else if (JSON.parse(this.initialAccountData).isDirectAccess.value !== this.accountData.isDirectAccess.value) {
        this.showChangeLXModal = true;
      } else {
        this.directAccessValidated = true;
        this.validateForm();
      }
    }

    private goToAccountPrograms() {
      this.$router.push('/account/programs');
    }

    handleDXModalCancel() {
      this.showChangeLXModal = false;
    }

    handleDXModalSave() {
      this.showChangeLXModal = false;
      this.directAccessValidated = true;
      this.shouldRedirectToPrograms = true;
      this.validateForm();
    }
    /* End of Direct access logic */

    public globalNameCheck: any = {
        'bxPartnerLeadFirstName': {
            value: '',
            required: false,
            minimunlength: false
        },
        'bxPartnerLeadLastName': {
            value: '',
            required: false,
            minimunlength: false
        },
        'bxSuccessLeadFirstName': {
            value: '',
            required: false,
            minimunlength: false
        },
        'bxSuccessLeadLastName': {
            value: '',
            required: false,
            minimunlength: false
        },
        'firstName': {
            value: '',
            required: false,
            minimunlength: false
        },
        'lastName': {
            value: '',
            required: false,
            minimunlength: false
        }
    }


    private account_data: any = {
        "accountAdminId": 0,
        "isFinalSubmit": true,
        "isDirectAccess": {"value": false, "required": false},
      singleSignOnEnabled: { value: false, required: false },
      automatedProvisioningEnabled: { value: false, required: false },
        "name": {
            "value": "",
            "required": true
        },
        "description": {
            "value": "",
            "required": false
        },
        "streetAddressLine1": {
            "value": "",
            "required": true
        },
        "streetAddressLine2": {
            "value": "",
            "required": false
        },
        "city": {
            "value": "",
            "required": true
        },
        "state": {
            "value": "",
            "required": true
        },
        "zipCode": {
            "value": "",
            "required": true
        },
        "type": {
            "value": "",
            "required": true,
            "errorStatus": false
        },
        "license": {
            "panoramaSku": {
                accountId: 0,
                "value": "",
                "required": true
            },
            "sublicenseSku": {
                accountId: 0,
                "value": "",
                "required": true
            },
            "salesforceAccountId": {
                accountId: 0,
                "value": "",
                "required": true
            }
        },
        "programAdmin": {
            "firstName": {
                "value": "",
                "required": true,
                "minimunlength": false
            },
            "lastName": {
                "value": "",
                "required": true,
                "minimunlength": false
            },
            "email": {
                "value": "",
                "required": true
            },
            "phoneNumber": {
                "value": "",
                "required": false
            },
            "phoneType": {
                "value": "",
                "required": false,
                "dropStatus": false,
            },
            "scheduleDate": {
                "value": "",
                "required": true,
                "errorStatus": false,
                "errorText": "",
            },
            "personalInvitationNote": {
                "value": "",
                "required": false
            },
            "id": {
                "value": 0,
                "required": false
            }
        },
        "programs": [
            {
                "id": {
                    "value": 0,
                    "required": false
                },
                "name": {
                    "value": "",
                    "required": false
                },
                "description": {
                    "value": "",
                    "required": false
                },
                "startDate": {
                    "value": "",
                    "required": false
                },
                "endDate": {
                    "value": "",
                    "required": false
                },
                "numberOfScholars": {
                    "value": "",
                    "required": false
                },
                "bxPartnerLeadFirstName": {
                    "value": "",
                    "required": true,
                    "minimunlength": false
                },
                "bxPartnerLeadLastName": {
                    "value": "",
                    "required": true,
                    "minimunlength": false
                },
                "bxSuccessLeadFirstName": {
                    "value": "",
                    "required": true,
                    "minimunlength": false
                },
                "bxSuccessLeadLastName": {
                    "value": "",
                    "required": true,
                    "minimunlength": false
                },
                "status": {
                    "value": "",
                }
            }
        ],
        "subscription": {
            "startDate": {
                "value": "",
                "required": true,
                "errorStatus": false
            },
            "endDate": {
                "value": "",
                "required": true,
                "errorStatus": false
            },
            "contractedScholars": {
                "value": "",
                "required": true,
                "errorStatus": false
            }
        }
    };

    /* istanbul ignore next */
    get SubscriptionEndStartDate() {
        let date:any = (<any>this.accountData).subscription.startDate.value?new Date((<any>this.accountData).subscription.startDate.value):new Date();
        date.setDate(date.getDate() + 1);
        return date;
    }

    /* istanbul ignore next */
    get subscriptionEndDate(){
        let startDate:any = (<any>this.accountData).subscription.startDate.value?new Date((<any>this.accountData).subscription.startDate.value): new Date();
        startDate.setDate(startDate.getDate() + 1);
        let endDate:any = startDate?new Date(startDate.setFullYear(startDate.getFullYear() + 1)):new Date()
        return endDate
    }

    /* istanbul ignore next */
    get isActiveScheduleDate(){
        let scheduleDate = this.initialScheduleDate ? new Date(APP_UTILITIES.formatShortDateFullYear(this.initialScheduleDate)).getTime(): ""
        let toDay:any = new Date().setDate(new Date().getDate())
        toDay = new Date(APP_UTILITIES.formatShortDateFullYear(toDay)).getTime()
        if(scheduleDate && (scheduleDate>toDay)){
            return true
        }
        return false
    }
    get accountBreadcrumbs(){
        return this.isEditMode ? this.breadcrumbs.editAccBreadcrumbs : this.breadcrumbs.newAccBreadcrumbs;
    }

    get AccountTypeList(){
        this.accoutTypesList && this.accoutTypesList.forEach((element: any, index: number) => {
            element.id = index;
            element.value = element.type;
            this.accountTypeDropdown.dropdownList.push(element)
        });
        return this.accountTypeDropdown;
    }

    get statesDD(){
        this.states && this.states.sort((a:any,b:any) => {
            if(a.state_code < b.state_code){
                return -1;
            }
            if(a.state_code > b.state_code){
                return 1;
            }
            return 0;
        });
        this.states && this.states.forEach((element: any) => {
            element.value = element.state_code;
            this.statesDropdown.dropdownList.push(element)
        });
        return this.statesDropdown;
    }

    private checkForCorrectDate(type: string, index: number) {
        let startDate = <HTMLInputElement>document.getElementById('ele_program_start_date');
        let endDate = <HTMLInputElement>document.getElementById('ele_program_end_date');
        /* istanbul ignore else */
        if (type === 'startDate') {
            let strstartDate = new Date((<any>this.accountData).programs[index].startDate.value).setHours(0, 0, 0, 0);
            let strendDate = new Date((<any>this.accountData).programs[index].endDate.value).setHours(0, 0, 0, 0);
            /* istanbul ignore else */
            if ((<any>this.accountData).programs[index].endDate.value != '') {
                /* istanbul ignore else */
                if (strendDate && strstartDate) {
                     /* istanbul ignore if */
                    if (strstartDate >= strendDate) {
                        (<any>this.accountData).programs[index].endDate.required = false;
                        setTimeout(() => {
                            this.dateIsGreater = true;
                            (<any>this.accountData).programs[index].endDate.required = false;
                            (<any>this.accountData).programs[index].endDate.value = '';
                            endDate.value = "";
                        })
                    } else {
                        this.dateIsGreater = false;
                        (<any>this.accountData).programs[index].endDate.required = true;
                        (<any>this.accountData).programs[index].startDate.required = true;
                    }
                }
            }
        } else
            if (type === APP_CONST.END_DATE) {
                if (startDate.value.length === APP_CONST.ZERO) {
                    (<any>this.accountData).programs[index].startDate.required = false;
                    setTimeout(() => { endDate.value = ""; startDate.value = ''; });
                } else {
                    (<any>this.accountData).programs[index].startDate.required = true;
                }
                let endStr: any = new Date((<any>this.accountData).programs[index].endDate.value).setHours(0, 0, 0, 0);
                let startStr: any = new Date((<any>this.accountData).programs[index].startDate.value).setHours(0, 0, 0, 0)
                if (endStr <= startStr) {
                    (<any>this.accountData).programs[index].endDate.required = false;
                    setTimeout(() => {
                        this.dateIsGreater = true;
                        (<any>this.accountData).programs[index].endDate.required = false;
                        (<any>this.accountData).programs[index].endDate.value = '';
                        endDate.value = "";
                    })
                } else {
                    this.dateIsGreater = false;
                    (<any>this.accountData).programs[index].endDate.required = true;
                    (<any>this.accountData).programs[index].startDate.required = true;
                }
            }
        this.isDataCanAutoSave = true;

    }

    get isFormDirty(){
        return this.initialAccountData!=JSON.stringify(this.accountData)
    }

    get accountData() {
        return this.account_data;
    }

    /* istanbul ignore next */
    set accountData(objData: account_details) {
        this.account_data = objData;
    }

    get getID() {
        return COMMON_CONST;
    }

    /* istanbul ignore next */
    get disableButton() {
            if(this.account_data.name.value || this.account_data.streetAddressLine1.value || this.account_data.streetAddressLine2.value || this.account_data.programAdmin.personalInvitationNote.value || this.account_data.city.value ||this.account_data.state.value || this.account_data.zipCode.value  || this.account_data.license.salesforceAccountId.value || this.account_data.programAdmin.firstName.value || this.account_data.programAdmin.lastName.value || this.account_data.programAdmin.email.value || this.account_data.programAdmin.phoneNumber.value.length>1 || this.account_data.programs[0].bxSuccessLeadFirstName.value || this.account_data.programs[0].bxSuccessLeadLastName.value || this.account_data.programs[0].bxPartnerLeadFirstName.value || this.account_data.programs[0].bxPartnerLeadLastName.value || this.buttonState || this.account_data.subscription.startDate.value || this.account_data.subscription.endDate.value || this.account_data.subscription.contractedScholars.value || this.account_data.programAdmin.scheduleDate.value)
            {
                return false;
            }
            return true;
    }

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    public checkAlphabet(event: any) {
        if (event.keyCode == 69) {
            return
        }
    }



    private validateValue(strFieldName: string, data: any): boolean {
        let isDataValid: boolean = false;
        let keyName: string = '';
        let isDefaultRequired = data.required;
        if (isDefaultRequired) {
            keyName = (this.numberOnlyField.indexOf(strFieldName) > -1) ? APP_CONST.NUMBER : keyName;
            keyName = (this.stringOnlyField.indexOf(strFieldName) > -1) ? APP_CONST.STRING : keyName;
            keyName = (this.emailOnlyField.indexOf(strFieldName) > -1) ? APP_CONST.EMAIL : keyName;
            keyName = (this.salesforceIdOnlyField.indexOf(strFieldName) > -1) ? APP_CONST.SALEFORCE_ACCOUNT_ID : keyName;

            let isValid: boolean = true;
            switch (keyName) {
                case APP_CONST.STRING:
                    if(!(/\S+/g.test(data.value)) && data.required)
                        data.required = false;
                    data.value = data.value.replace(/\s+/g, ' ').trim();
                    /* istanbul ignore if */
                    if (!isValid && strFieldName === 'city') {
                        this.invalidCity = true;
                    }
                    /* istanbul ignore else */
                    if (data.required) {
                        data.required = isValid;
                    }
                    break;

                case APP_CONST.NUMBER:
                     /* istanbul ignore if */
                    if (strFieldName === APP_CONST.PHONE_NUMBER) {
                        let cleaned: any = ('' + data.value).replace(/\D/g, '')
                        isValid = (String(cleaned).length === 10)

                    } else if (strFieldName === APP_CONST.NO_OF_SCHOLARS) {
                        isValid = (data.value >= 1)
                    } else if (strFieldName === "zipCode") {
                        /* istanbul ignore else */
                        if (String(data.value).length === 5) {
                            this.zipcodeRequired = false
                            this.invalidZipcode = false;
                            isValid = (String(data.value).length === 5)
                        } else {
                            this.zipcodeRequired = true
                            this.invalidZipcode = false;
                        }

                    } else {
                        isValid = !isNaN(data.value);
                    }
                    /* istanbul ignore else */
                    if (data.required === true) {
                        data.required = isValid;
                    }
                    break;
                case APP_CONST.SALEFORCE_ACCOUNT_ID:
                     /* istanbul ignore if */
                    if (String(data.value).length >= this.salesforceIdMinLength && String(data.value).length <= this.salesforceIdMaxLength) {
                        this.salesforceIdLength = false;
                        data.required = this.salesforceIdValidator(data.value);
                    }
                     /* istanbul ignore else */
                     else {
                        this.salesforceIdLength = true;
                        data.required = false;
                    }
                    break;

            }


            isDataValid = data.required;
        }

        if (isDefaultRequired === false) {
            isDataValid = true;
        }
        return isDataValid;

    }


    selectPanorama(panorama: { id: string, value: string, sku: string }) {
        this.account_data.license.panoramaSku.accountId = panorama.id;
        this.account_data.license.panoramaSku.value = panorama.value;
        this.panoramaDropdown.value = panorama.value;
        this.panoramaDropdown.id = panorama.id;
        this.openDropPanorama = false;
        if (panorama.id.length > APP_CONST.ZERO) {
            this.sublicenses = [];
            this.getSublicenses(panorama.id);
            this.sublicenseDropdown.dropdownList = [];
            this.sublicenseDropdown = {...this.sublicenseDropdown, id: "0", value: 'Select Sublicense', dropdownList : []}
            this.selectSublicense({ id: "0", value: 'Select Sublicense', sku: '' , panoramaSku : '' });
            this.buttonState=true;
        }
        else {
            this.selectSublicense({ id: "0", value: 'Select Sublicense', sku: '',panoramaSku : '' });
            this.sublicenses = [];
            this.sublicenseDropdown.dropdownList = [];
            this.buttonState=false;
        }
    }

    /* Handle adding package with/out grades */
    private addPackageToCollection(licenses: any[], newLicense: any, checked: boolean) {
        const existingPackageIndex = licenses.findIndex(p => p.cmsSubscriptionId === newLicense.cmsSubscriptionId);
        if (existingPackageIndex === -1) {
          return [...licenses,  newLicense];
        }

        if (newLicense.grades !== undefined) {
          return [
            ...licenses.slice(0, existingPackageIndex),
            {
              ...licenses[existingPackageIndex],
              grades: newLicense.grades,
            },
            ...licenses.slice(existingPackageIndex + 1),
          ];
        }

        return checked ? licenses : [
          ...licenses.slice(0, existingPackageIndex),
          ...licenses.slice(existingPackageIndex + 1),
        ];
    }

    selectContentPackage(contentPackage: { id: string, value: string, checked: boolean, options?: string[] }) {
        this.buttonState = true;
        const acctData = (<any>this.accountData);
        const accountId = acctData && acctData.id && acctData.id.value || 0;

        if(contentPackage.checked){
            // Add Item
            if(this.isEditMode){
                //Editing
                const data = acctData && acctData.cmsLicenses && acctData.cmsLicenses.value || [];
                acctData.cmsLicenses.value = this.addPackageToCollection(data, {
                    accountId,
                    cmsSubscriptionId: contentPackage.value,
                    status:1,
                    grades: contentPackage.options,
                }, contentPackage.checked);
            } else {
                //New Account
                const data = acctData && acctData.cmsLicenses || [];
                acctData.cmsLicenses = this.addPackageToCollection(data, {
                    accountId,
                    cmsSubscriptionId: contentPackage.value,
                    status:1,
                    grades: contentPackage.options,
                }, contentPackage.checked);
            }
        } else{
            // Remove Item
            if(this.isEditMode){
                const data = acctData.cmsLicenses.value.filter((item: any) => item.cmsSubscriptionId !== contentPackage.value);
                acctData.cmsLicenses.value = data;
            } else {
                const data = acctData.cmsLicenses.filter((item: any) => item.cmsSubscriptionId !== contentPackage.value);
                acctData.cmsLicenses = data;
            }
        }
    }

    selectSublicense(sublicense: { id: string, value: string, sku: string, panoramaSku : string }) {
        let filteredValue: any = this.sublicenseDropdown.dropdownList.filter((element: any) => { return element.id == sublicense.id});
        this.account_data.license.sublicenseSku.accountId = sublicense.id;
        this.account_data.license.sublicenseSku.value = sublicense.value;
        this.sublicenseDropdown.value = sublicense.value;
        this.sublicenseDropdown.id = sublicense.id;
        this.licensesublicenseSku = filteredValue[0] && filteredValue[0].sku;
        this.licensePanoramaSku = filteredValue[0] && filteredValue[0].panoramaSku;
    }

    getContentSubscriptions() {
        const acctData: any = (this.accountData);
        getAllSubscriptionCollections().then(async (res: any) => {
            /* istanbul ignore else */
            //console.log('cms licenses', acctData.cmsLicenses);
            const subs = acctData.cmsLicenses && acctData.cmsLicenses.value.map((item: any) => item.cmsSubscriptionId
            ) || [];
            /* Grades dropdown feature flag */
            const subsWithGrades = acctData.cmsLicenses && acctData.cmsLicenses.value.filter((item: any) => item.grades !== undefined);
            const lxEnhancementsEnabled = await APP_UTILITIES.getFeatureFlag(APP_CONST.FEATURE_KEYS.cmsLxEnhancements);
            const dropdownItems = res.map((item: any) => ({
              label: item.subscriptionTitle,
              value: item.sys.id,
              meta: item,
              options: lxEnhancementsEnabled && item.subscriptionTitle.toLowerCase().includes('scholastic') // TODO: Replace with a property defined in contentful
                ? APP_CONST.GRADES.map(v => ({
                  id: v,
                  value: v,
                  isChecked: false
                }))
                : []
            }));
            if(subs.length > 0){
                const checkedItems = dropdownItems.map((ddItem: any) => {
                  const dropdownItem = {...ddItem, isChecked: subs.includes(ddItem.value)};
                  const itemWithGrades = subsWithGrades.find((x: any) => x.cmsSubscriptionId === ddItem.value);
                  if (itemWithGrades !== undefined) {
                    dropdownItem.options = ddItem.options.map((o: any) => ({...o,  isChecked: itemWithGrades.grades.includes(o.value)}));
                  }
                  return dropdownItem;
                });
                this.contentSubscriptionDropdown.options = checkedItems;
            }
            else {
                this.contentSubscriptionDropdown.options = dropdownItems;
            }
            const isNew = !this.isEditMode;
            // Check the Essentials License in the dropdown
            if(isNew){
                this.contentSubscriptionDropdown.options.forEach((item: any) => {
                    if (item.value === APP_CONST.CONTENTFUL_ESSENTIALS_SUBSCRIPTION_ID) {
                        item.isChecked = true;
                        if(!this.account_data.cmsLicenses){
                            this.account_data['cmsLicenses'] = [
                                {
                                    accountId: 0,
                                    cmsSubscriptionId: item.value,
                                    status: 1,
                                }
                            ];
                        }
                    }
                });
            }
        });
    }

    getPanoramas() {
        getPanoramaSKU().then((res: any) => {
            /* istanbul ignore else */
            if (res.status === APP_CONST.RESPONSE_200) {
                const data = JSON.parse(JSON.stringify(res.data));
                data.forEach((element: any) => {
                    element.value = element.name;
                    if(this.panoramaDropdown.value != 'Select Panorama' && (this.panoramaDropdown.value == element.value)){
                        this.panoramaDropdown.id = element.id;
                        this.getSublicenses(element.id);
                    }
                });
                this.panoramaDropdown.dropdownList = data;
                this.panoramas = data as IPanorama[];
            }
        });
    }

    getSublicenses(panoramaId: string) {
        getSublicenseByPanorama(panoramaId).then((res: any) => {
            /* istanbul ignore else */
            if (res.status === APP_CONST.RESPONSE_200) {
                const data = JSON.parse(JSON.stringify(res.data));
                data.forEach((element: any) => {
                    element.value = element.name;
                    if(this.sublicenseDropdown.value != 'Select Sublicense' && (this.sublicenseDropdown.value == element.value)){
                        this.sublicenseDropdown.id = element.id
                    }
                });
                this.sublicenseDropdown.dropdownList = data;
                this.sublicenses = data as ISublicense[];
            }
        });
    }

    public resetToOldStatus() {
        for (let strKey in this.accountData) {
            let itemNode: any = (<any>this.accountData)[strKey]

            if (this.itemToIgnore.indexOf(strKey) < 0 && strKey != APP_CONST.PROGRAMS && strKey != APP_CONST.PROGRAM_ADMIN && strKey != APP_CONST.LICENSE) {
                itemNode.required = (this.notRequired.indexOf(strKey) == -1);
            } else if (strKey === APP_CONST.PROGRAMS) {
                for (let k in itemNode) {
                    for (let childKey in itemNode[k]) {
                        let childNode: any = itemNode[k][childKey]
                        childNode.required = (this.notRequired.indexOf(childKey) == -1);

                    }
                }
            } else if (strKey === APP_CONST.PROGRAM_ADMIN) {
                for (let childKey in itemNode) {
                    let childNode: any = itemNode[childKey]
                    childNode.required = (this.notRequired.indexOf(childKey) == -1);
                }
            } else if (strKey === APP_CONST.LICENSE) {
                for (let key in itemNode) {
                    /* istanbul ignore else */
                    if (itemNode.hasOwnProperty(key)) {
                        let licenceNode = itemNode[key];
                        /* istanbul ignore else */
                        if (typeof licenceNode === 'object' && licenceNode !== null) {
                            licenceNode.required = (this.notRequired.indexOf(key) == -1);
                        }
                    }
                }
            }
        }
    }

    public validateForm() {
        /* Validate and show the modal only if the flag is enabled */
        if (this.directAccessEnabled && !this.directAccessValidated) {
          this.validateDirectAccess();
          return;
        }

        this.checkForCorrectEmail = true;
        this.duplicateEmail = false;
        this.invalidCity = false;
        this.invalidSalesforceId = false;
        this.salesforceIdLength = false;
        this.resetToOldStatus();
        let dataToSend: any = {}
        dataToSend.accountAdminId = APP_UTILITIES.getCookie(APP_CONST.USER_ID);
        dataToSend.isFinalSubmit = true;
        this.isEdited = false;
        let isDataCanSave: boolean = true;
        this.isDataCanAutoSave = false;
        let isFilledDataValid: boolean = true;
        for (let strKey in this.accountData) {
            let itemNode: any = (<any>this.accountData)[strKey]
            if (strKey != APP_CONST.PROGRAMS && strKey != APP_CONST.PROGRAM_ADMIN && strKey != APP_CONST.LICENSE) {
                if (this.itemToIgnore.indexOf(strKey) < 0) {
                    if (itemNode.required === true) {
                        itemNode.required = (itemNode.value != "" && itemNode.value != "0");
                        /* istanbul ignore else */
                        if (isDataCanSave === true) {
                            isDataCanSave = (itemNode.value != "" && itemNode.value != "0")

                        }

                    }

                    let bValid = this.validateValue(strKey, itemNode);
                    /* istanbul ignore else */
                    if (isFilledDataValid === true) {
                        isFilledDataValid = bValid;
                    }
                    dataToSend[strKey] = itemNode.value;
                }

            }
            else if (strKey === APP_CONST.PROGRAMS) {
                dataToSend[strKey] = [];
                for (let k = 0; k < itemNode.length; k++) {
                    let childData: any = {}
                    for (let childKey in itemNode[k]) {
                        if (this.requiredKeyForProgram.indexOf(childKey) != -1) {
                            let childNode: any = itemNode[k][childKey]
                            /* istanbul ignore else */
                            if (childNode.required === true) {
                                childNode.required = (childNode.value != "");
                                /* istanbul ignore if */
                                if (isDataCanSave === true) {
                                    isDataCanSave = (childNode.value != "")
                                }
                            }
                            dataToSend[childKey] = childNode.value
                            childData[childKey] = childNode.value;
                            let bValid = this.validateValue(childKey, childNode);
                            /* istanbul ignore else */
                            if (isFilledDataValid === true) {
                                isFilledDataValid = bValid;
                            }
                        }
                    }
                    dataToSend[strKey].push(childData)
                }
            }
            else if (strKey === APP_CONST.LICENSE) {
                dataToSend[strKey] = {}
                let childObject: any = {};
                for (let childKey in itemNode) {
                    let childNode: any = itemNode[childKey]
                    if (childNode.value === 'Select Sublicense' || childNode.value === 'Select Panorama' || childNode.value === 'salesforceAccountId') {
                        childNode.value = ""
                    }
                    /* istanbul ignore else */
                    if (childNode.required === true) {
                        childNode.required = (childNode.value != "");
                        /* istanbul ignore if */
                        if (isDataCanSave === true) {
                            isDataCanSave = (childNode.value != "")

                        }
                    }
                    childObject[childKey] = childNode.value;
                    let bValid = this.validateValue(childKey, childNode);
                    /* istanbul ignore else */
                    if (isFilledDataValid === true) {
                        isFilledDataValid = bValid;
                    }

                }
                dataToSend[strKey] = childObject;
            }



            else if (strKey === APP_CONST.PROGRAM_ADMIN) {
                dataToSend[strKey] = {}
                let childObject: any = {
                    status: 0
                };
                for (let childKey in itemNode) {
                    let childNode: any = itemNode[childKey]
                    if (childNode.required === true) {
                        childNode.required = (childNode.value != "");
                        /* istanbul ignore if */
                        if (isDataCanSave === true) {
                            isDataCanSave = (childNode.value != "")
                            if(childKey == APP_CONST.PHONE_TYPE){
                                isDataCanSave = true
                            }
                        }


                    }
                    if (childKey === APP_CONST.PHONE_NUMBER) {
                        let cleaned: any = (childNode.value && childNode.value.charAt(0) == '0') ? '' : ('' + childNode.value).replace(/\D/g, '');

                        if (cleaned != '' && cleaned.length > 10) {
                            childObject[childKey] = cleaned.substring(0, 10);
                        } else {
                            childObject[childKey] = cleaned
                        }
                    } else {
                        childObject[childKey] = childNode.value;
                    }

                    let bValid = this.validateValue(childKey, childNode);
                    /* istanbul ignore else */
                    if (isFilledDataValid === true) {
                        isFilledDataValid = bValid;
                    }

                }
                dataToSend[strKey] = childObject;
            }

        }
        (<any>this.accountData).type.errorStatus = false;
        let errorCheck:boolean = false;
        (<any>this.accountData).subscription.contractedScholars.errorStatus = false;
        (<any>this.accountData).subscription.startDate.errorStatus = false;
        (<any>this.accountData).subscription.endDate.errorStatus = false;
        (<any>this.accountData).programAdmin.scheduleDate.errorStatus = false
        if((<any>this.accountData).subscription.contractedScholars.value.length == 0){
            (<any>this.accountData).subscription.contractedScholars.errorStatus = true;
            errorCheck = true
        }

        if((<any>this.accountData).subscription.endDate.value.length == 0){
            (<any>this.accountData).subscription.endDate.errorStatus = true;
            errorCheck = true
        }

        if((<any>this.accountData).programAdmin.scheduleDate.value.length === 0){
            (<any>this.accountData).programAdmin.scheduleDate.errorStatus = true;
            errorCheck = true
        }

        if((<any>this.accountData).programAdmin.scheduleDate.errorText){
            if((<any>this.accountData).programAdmin.scheduleDate.errorStatus){
                errorCheck = true
            }
        }

        if((<any>this.accountData).subscription.startDate.value.length == 0){
            (<any>this.accountData).subscription.startDate.errorStatus = true;
            errorCheck = true
        }

        if((<any>this.accountData).type.value == 'Select'){
            (<any>this.accountData).type.errorStatus = true;
            errorCheck = true;
        }

        let validateFnameAndLnameResponse = this.validateFnameAndLname(dataToSend)
        /* istanbul ignore else */
        if (validateFnameAndLnameResponse) {
            this.isPhoneCanSave = true;
            if (dataToSend.programAdmin && dataToSend.programAdmin.phoneNumber && dataToSend.programAdmin.phoneNumber.length < 10) {
                this.isPhoneCanSave = false;
            } else {
                this.isPhoneCanSave = true;
            }
            this.checkForCorrectEmail = true;

            this.isDataCanSaveGlobal = isDataCanSave;
            /* istanbul ignore else */
            if (this.isEditMode && isDataCanSave === true && isFilledDataValid && this.isPhoneCanSave && this.checkForCorrectEmail && !errorCheck) {
                this.updateAccountData(this.reNameKeyForServer(this.sendLicencesValueToServer(dataToSend)));
            }
            else if (isDataCanSave === true && isFilledDataValid && this.isPhoneCanSave && this.checkForCorrectEmail && !errorCheck) {
                this.saveNewAccountData(this.reNameKeyForServer(this.sendLicencesValueToServer(dataToSend)));
            }
        }
    }

    reNameKeyForServer(dataToServer: any) {
        dataToServer.programAdmin = [dataToServer[APP_CONST.PROGRAM_ADMIN]]
        delete dataToServer[APP_CONST.PROGRAMS];
        let sendDataToServer = JSON.stringify(dataToServer);
        sendDataToServer = sendDataToServer.replace('programAdmin', 'accountAdmins');
        return JSON.parse(sendDataToServer)
    }

    reNameKeyForRenderData(dataToServer: any) {
         /* istanbul ignore else */
        if (dataToServer['accountAdmins']) {
            dataToServer.accountAdmins = dataToServer['accountAdmins'][0]
            let sendDataToServer = JSON.stringify(dataToServer);
            sendDataToServer = sendDataToServer.replace('accountAdmins', 'programAdmin');
            return JSON.parse(sendDataToServer)
        } else {
            return dataToServer
        }
    }

    public validateFnameAndLname(dataToSend: any) {
        /* istanbul ignore else */
        if (dataToSend.programs) {
            /* istanbul ignore else */
            if (dataToSend.programs[0].bxPartnerLeadFirstName.length > 0) {
                this.globalNameCheck.bxPartnerLeadFirstName.required = false
                this.globalNameCheck.bxPartnerLeadFirstName.minimunlength = false
                let bxPartnerLeadFirstName = APP_UTILITIES.validateAlphaOnly(dataToSend.programs[0].bxPartnerLeadFirstName)
                let bxPartnerLeadFirstNameMinimumLength = APP_UTILITIES.checkMinimumLength(dataToSend.programs[0].bxPartnerLeadFirstName)
                if (!bxPartnerLeadFirstName || bxPartnerLeadFirstNameMinimumLength) {
                    this.globalNameCheck.bxPartnerLeadFirstName.required = !bxPartnerLeadFirstName
                    this.globalNameCheck.bxPartnerLeadFirstName.minimunlength = bxPartnerLeadFirstNameMinimumLength
                }
            }
            /* istanbul ignore else */
            if (dataToSend.programs[0].bxPartnerLeadLastName.length > 0) {
                this.globalNameCheck.bxPartnerLeadLastName.required = false
                this.globalNameCheck.bxPartnerLeadLastName.minimunlength = false
                let bxPartnerLeadLastName = APP_UTILITIES.validateAlphaOnly(dataToSend.programs[0].bxPartnerLeadLastName)
                let bxPartnerLeadLastNameMinimumLength = APP_UTILITIES.checkMinimumLength(dataToSend.programs[0].bxPartnerLeadLastName)
                if (!bxPartnerLeadLastName || bxPartnerLeadLastNameMinimumLength) {
                    this.globalNameCheck.bxPartnerLeadLastName.required = !bxPartnerLeadLastName
                    this.globalNameCheck.bxPartnerLeadLastName.minimunlength = bxPartnerLeadLastNameMinimumLength

                }
            }
            /* istanbul ignore else */
            if (dataToSend.programs[0].bxSuccessLeadFirstName.length > 0) {
                this.globalNameCheck.bxSuccessLeadFirstName.required = false
                this.globalNameCheck.bxSuccessLeadFirstName.minimunlength = false
                let bxSuccessLeadFirstName = APP_UTILITIES.validateAlphaOnly(dataToSend.programs[0].bxSuccessLeadFirstName)
                let bxSuccessLeadFirstNameMiinmumLength = APP_UTILITIES.checkMinimumLength(dataToSend.programs[0].bxSuccessLeadFirstName)
                if (!bxSuccessLeadFirstName || bxSuccessLeadFirstNameMiinmumLength) {
                    this.globalNameCheck.bxSuccessLeadFirstName.required = !bxSuccessLeadFirstName
                    this.globalNameCheck.bxSuccessLeadFirstName.minimunlength = bxSuccessLeadFirstNameMiinmumLength
                }
            }
            /* istanbul ignore else */
            if (dataToSend.programs[0].bxSuccessLeadLastName.length > 0) {
                this.globalNameCheck.bxSuccessLeadLastName.required = false
                this.globalNameCheck.bxSuccessLeadLastName.minimunlength = false
                let bxSuccessLeadLastName = APP_UTILITIES.validateAlphaOnly(dataToSend.programs[0].bxSuccessLeadLastName)
                let bxSuccessLeadLastNameMiinmumLength = APP_UTILITIES.checkMinimumLength(dataToSend.programs[0].bxSuccessLeadLastName)
                if (!bxSuccessLeadLastName || bxSuccessLeadLastNameMiinmumLength) {
                    this.globalNameCheck.bxSuccessLeadLastName.required = !bxSuccessLeadLastName
                    this.globalNameCheck.bxSuccessLeadLastName.minimunlength = bxSuccessLeadLastNameMiinmumLength

                }
            }
        }
        /* istanbul ignore else */
        if (dataToSend.programAdmin) {
            /* istanbul ignore else */
            if (dataToSend.programAdmin.firstName.length > 0) {
                this.globalNameCheck.firstName.required = false
                this.globalNameCheck.firstName.minimunlength = false
                let firstName = APP_UTILITIES.validateAlphaOnly(dataToSend.programAdmin.firstName);
                let firstNameMinimumLength = APP_UTILITIES.checkMinimumLength(dataToSend.programAdmin.firstName);
                if (!firstName || firstNameMinimumLength) {
                    this.globalNameCheck.firstName.required = !firstName
                    this.globalNameCheck.firstName.minimunlength = firstNameMinimumLength
                }
            }
            /* istanbul ignore else */
            if (dataToSend.programAdmin.lastName.length > 0) {
                this.globalNameCheck.lastName.required = false
                this.globalNameCheck.lastName.minimunlength = false
                let lastName = APP_UTILITIES.validateAlphaOnly(dataToSend.programAdmin.lastName);
                let lastNameMinimumLength = APP_UTILITIES.checkMinimumLength(dataToSend.programAdmin.lastName);
                if (!lastName || lastNameMinimumLength) {
                    this.globalNameCheck.lastName.required = !lastName
                    this.globalNameCheck.lastName.minimunlength = lastNameMinimumLength
                }
            }
        }
        let breakLoop = false
        for (let keys in this.globalNameCheck) {
            /* istanbul ignore else */
            if (this.globalNameCheck[keys]) {
                if (this.globalNameCheck[keys].required === true || this.globalNameCheck[keys].minimunlength === true) {
                    breakLoop = true
                    break
                }
            }
        }
        if (breakLoop) {
            return false
        }
        return true
    }

    private saveNewAccountData(dataToSend: any) {
        const aYearFromNow = new Date();
        aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
        dataToSend.subscription = {
            "accountId": 0,
            "contractedScholars": (<any>this.accountData).subscription && (<any>this.accountData).subscription.contractedScholars.value,
            "StartDate": (<any>this.accountData).subscription && (<any>this.accountData).subscription.startDate.value ? APP_UTILITIES.formatFullYearDate((<any>this.accountData).subscription.startDate.value): null,
            "EndDate": (<any>this.accountData).subscription && (<any>this.accountData).subscription.endDate.value ? APP_UTILITIES.formatFullYearDate((<any>this.accountData).subscription.endDate.value): null,
        }
        if(this.account_data && this.account_data.cmsLicenses){
            const licenses = this.account_data.cmsLicenses;
            dataToSend['cmsLicenses'] = licenses;
        }
        if(dataToSend.accountAdmins){
            dataToSend.accountAdmins[0].scheduleDate = (<any>this.accountData).programAdmin.scheduleDate.value ? APP_UTILITIES.formatFullYearDate((<any>this.accountData).programAdmin.scheduleDate.value): null;
        }
        this.isValidate = false;
        storeInterceptorModule.saveAccountData(dataToSend).then(response => {
            this.saveAccountDataRes(response)
        })
    }

    saveAccountDataRes(response: any) {
        if (response.status === APP_CONST.RESPONSE_200) {
            this.duplicateEmail = false
            this.duplicateEmailMsg = ''
            this.$store.dispatch('globalModule/assignAccountId', response.data.id);
            APP_UTILITIES.setCookie('accountId', JSON.stringify(response.data.id), 1);
            this.apiResponseCode = APP_CONST.RESPONSE_200;
            this.clearForm()
        }
         /* istanbul ignore next */
         else if (response.status === APP_CONST.RESPONSE_400 && response.data.errors && response.data.errors["accountAdmins[0].Email"] && response.data.errors["accountAdmins[0].Email"][0]) {
            /* istanbul ignore else */
            this.apiResponseCode = APP_CONST.RESPONSE_400;
            this.checkForCorrectEmail = false;
        }
         /* istanbul ignore next */
        else if (response.status === APP_CONST.RESPONSE_400) {
            this.apiResponseCode = APP_CONST.RESPONSE_400;
             /* istanbul ignore else */
            if (response.data.name === "Error_UserAlreadyRegistered") {
                this.duplicateEmail = true;
                this.duplicateEmailMsg = response.data.value
            }
        }
    }


    private updateAccountData(dataToSend: any) {
        this.isValidate = false;
        dataToSend.subscription = {
            "accountId": 0,
            "contractedScholars": (<any>this.accountData).subscription.contractedScholars.value,
            "StartDate": (<any>this.accountData).subscription.startDate.value ? APP_UTILITIES.formatFullYearDate((<any>this.accountData).subscription.startDate.value): null,
            "EndDate": (<any>this.accountData).subscription.endDate.value ? APP_UTILITIES.formatFullYearDate((<any>this.accountData).subscription.endDate.value): null,
        }
        if(dataToSend.accountAdmins){
            dataToSend.accountAdmins[0].scheduleDate = (<any>this.accountData).programAdmin.scheduleDate.value ? APP_UTILITIES.formatFullYearDate((<any>this.accountData).programAdmin.scheduleDate.value): null;
        }

        storeInterceptorModule.updateAccountData(dataToSend).then(response => {
            /* istanbul ignore else */
            if ((<any>response).status === APP_CONST.RESPONSE_200) {
                this.duplicateEmail = false
                this.duplicateEmailMsg = ''
                this.apiResponseCode = APP_CONST.RESPONSE_200;
                setTimeout(()=> {
                  if (this.shouldRedirectToPrograms) {
                    this.goToAccountPrograms();
                  } else {
                    this.backToAccountList();
                  }
                }, 10);

            }
            else {
                this.apiResponseCode = APP_CONST.RESPONSE_400;
                let data:any = JSON.parse((<any>response).data);
                if(data.name && data.name == "Error_UserAlreadyRegistered"){
                    this.duplicateEmail = true;
                    this.duplicateEmailMsg = data.value
                }
            }
        })
    }

    showEditAccountPopup() {
        /* istanbul ignore else */
        if (UIkit.modal('#modal-invitation-Update')) {
            UIkit.modal('#modal-invitation-Update').show();
        }
    }

    showNewAccountPopup() {
        /* istanbul ignore else */
        if (UIkit.modal('#modal-invitation-sent')) {
            UIkit.modal('#modal-invitation-sent').show();
        }
    }

    public clearForm() {
        this.type = "";
        this.buttonState = false;
        this.isEdited = false;
        for (let strKey in this.accountData) {
            let itemNode: any = (<any>this.accountData)[strKey]

            if (this.itemToIgnore.indexOf(strKey) < 0 && strKey != APP_CONST.PROGRAMS && strKey != APP_CONST.PROGRAM_ADMIN) {

                if (strKey == 'license') {
                    itemNode.panoramaSku && (itemNode.panoramaSku.value = "");
                    itemNode.sublicenseSku && (itemNode.sublicenseSku.value = "");
                    itemNode.salesforceAccountId && (itemNode.salesforceAccountId.value = "");
                } else {
                    itemNode.value = "";
                    itemNode.required = (this.notRequired.indexOf(strKey) == -1);
                }

            } else if (strKey === APP_CONST.PROGRAMS) {
                for (let k = 0; k < itemNode.length; k++) {
                    for (let childKey in itemNode[k]) {
                        let childNode: any = itemNode[k][childKey]
                        childNode.value = '';
                        childNode.required = (this.notRequired.indexOf(childKey) == -1);

                    }
                }
            } else if (strKey === APP_CONST.PROGRAM_ADMIN) {
                for (let childKey in itemNode) {
                    let childNode: any = itemNode[childKey]
                    childNode.value = '';
                    childNode.required = (this.notRequired.indexOf(childKey) == -1);
                }
            }
        }
        this.zipcodeRequired = false;
        this.invalidZipcode = false;
        this.licensePanoramaSku = '';
        this.licensesublicenseSku = '';
        this.accountData.license.panoramaSku.required = true;
        this.accountData.license.sublicenseSku.required = true;
        this.accountData.license.salesforceAccountId.required = true;
        this.isDataCanSaveGlobal = true;
        this.mapSelectedAccountType();
    }

    private handleInputFieldKeyUp() {
        if (this.isEdited == false) {
            this.isEdited = true;
        }
    }

    public getAccountId() {
        let getAccountIdFromCookie: any = APP_UTILITIES.getCookie('accountId')
        let accountId = (getAccountIdFromCookie && JSON.parse(getAccountIdFromCookie)) || 0;
        return accountId;
    }

    public async beforeMount() {
        this.getPanoramas();
        this.highestRole = Number(APP_UTILITIES.getCookie("highest_role"));
        this.setAccountData();
        this.states = APP_UTILITIES.removeStates(this.states, this.removedStates);
      [this.directAccessEnabled, this.singleSignOnEnabled] = await Promise.all([
        APP_UTILITIES.getFeatureFlag(APP_CONST.FEATURE_KEYS.learningExperienceDirectAccess),
        APP_UTILITIES.getFeatureFlag(APP_CONST.FEATURE_KEYS.singleSignOn)
      ]);
    }

    public setAccountData() {
        if (this.$route.name === COMMON_CONST.EDIT_ACCOUNT) {
            this.isEditMode = true;
            this.accountDt = {} as AccountDetails;
            let accountId = this.getGlobalState.accountId === 0 ? this.getAccountId() : this.getGlobalState.accountId;
            this.fetchAccountDetails(String(accountId));
        } else {
            this.isEditMode = false;
            this.getContentSubscriptions();
        }
    }

    public fetchAccountDetails(accountId: string): any {
        storeInterceptorModule.fetchExistingAccountDetails(accountId).then(response => {
            let responseData: any = ((<any>response).data === undefined) ? null : (<any>response).data
            return this.fillDataForEdit(this.reNameKeyForRenderData(this.setLicencesValue(responseData)))
        }).then(this.getContentSubscriptions);
    }

    public loadSavedData(): any {
        let user_id: any = APP_UTILITIES.getCookie(APP_CONST.USER_ID);
        storeInterceptorModule.getAccountData(user_id).then(response => {
            let responseData: any = ((<any>response).data === undefined) ? null : (<any>response).data
            return this.fillFormWithAPIData(this.reNameKeyForRenderData(this.setLicencesValue(responseData)))
        })
        return this.accountData;
    }

      private prepareProgramValues(dataObject: any): any {
        let itemToReturn: any = [];
        let baseItemData: any = this.account_data[APP_CONST.PROGRAMS];
        for (let i = 0; i < baseItemData.length; i++) {
            let itemToPush: any = {}
            for (let strKey in baseItemData[i]) {
                const checkObj = (typeof dataObject == 'object' && dataObject) ? ((dataObject.hasOwnProperty(i) && dataObject[i].hasOwnProperty(strKey) && dataObject[i][strKey]) || '') : '';
                const val = Array.isArray(dataObject) && dataObject.length >= i && dataObject[i] ? ((!dataObject[i][strKey] && (dataObject[i][strKey] !== 0)) ? '' : dataObject[i][strKey]) : checkObj;
                itemToPush[strKey] = { value: val, required: (this.notRequired.indexOf(strKey) < 0) }
            }
            itemToReturn.push(itemToPush)
        }
        return itemToReturn;
    }

    private prepareLicenseData(dataObject: any): any {
        let baseItemData: any = this.account_data[APP_CONST.LICENSE];
        let itemToReturn: any = {};
        for (let strKey in baseItemData) {
            /* istanbul ignore else */

            if(strKey == "panoramaSku"){
                itemToReturn[strKey] =  { value: ((dataObject['panorama'] === undefined) || (dataObject['panorama'] === null) ? '' : dataObject['panorama']), required: (this.notRequired.indexOf(strKey) < 0) }
            } else if (strKey == "sublicenseSku") {
                itemToReturn[strKey] =  { value: ((dataObject['sublicense'] === undefined) || (dataObject['sublicense'] === null) ? '' : dataObject['sublicense']), required: (this.notRequired.indexOf(strKey) < 0) }
            } else if (dataObject[strKey]) {
                itemToReturn[strKey] = { value: ((dataObject[strKey] === undefined) || (dataObject[strKey] === null) ? '' : dataObject[strKey]), required: (this.notRequired.indexOf(strKey) < 0) }
            } else {
                itemToReturn[strKey] = baseItemData[strKey];
            }
        }
        return itemToReturn;
    }


    private prepareProgramAdminData(dataObject: any): any {
        let baseItemData: any = this.account_data[APP_CONST.PROGRAM_ADMIN];
        let itemToReturn: any = {};
        for (let strKey in baseItemData) {
            if (dataObject[strKey]) {

                if (strKey === APP_CONST.PHONE_NUMBER) {
                    let strtext: string = dataObject[strKey];
                    const zip = strtext && strtext.substring(0, 3);
                    const middle = strtext && strtext.substring(3, 6);
                    const last = strtext && strtext.substring(6, 10);
                    itemToReturn[strKey] = { value: `(${zip}) ${middle}-${last}`, required: (this.notRequired.indexOf(strKey) < 0) }
                    if (itemToReturn[strKey].value === '(0) -') {
                        itemToReturn[strKey].value = ''
                    }

                } else if(strKey === APP_CONST.PHONE_TYPE){
                    itemToReturn[strKey] = { value: dataObject[strKey], required: false, dropStatus: false }
                }
                else {
                    itemToReturn[strKey] = { value: (dataObject[strKey] === undefined || dataObject[strKey] === null ? '' : dataObject[strKey]), required: (this.notRequired.indexOf(strKey) < 0) }
                }
            } else {

                itemToReturn[strKey] = baseItemData[strKey];
            }
        }
        return itemToReturn;
    }

    setLicencesValue(dataObject: any) {
         /* istanbul ignore else */
        if (dataObject) {
            let licenceData = JSON.parse(JSON.stringify(dataObject))
            this.licensePanoramaSku = licenceData.license.panoramaSku ? licenceData.license.panoramaSku : ''
            this.licensesublicenseSku = licenceData.license.sublicenseSku ? licenceData.license.sublicenseSku : ''
            let licenceData1 = JSON.parse(JSON.stringify(dataObject))
            licenceData1.license.panoramaSku = licenceData.license && dataObject.license.panoramaSku ? licenceData.license.panoramaSku : ''
            let licence = JSON.parse(JSON.stringify(licenceData1))
            licence.license.sublicenseSku = licenceData.license && licenceData.license.sublicenseSku ? licenceData.license.sublicenseSku : ''
            return licence
        }
        return dataObject
    }

    sendLicencesValueToServer(dataToSend: any) {
         /* istanbul ignore else */
        if (dataToSend) {
            let licenceData = JSON.parse(JSON.stringify(dataToSend))
            let licenceData1 = JSON.parse(JSON.stringify(dataToSend))
            licenceData1.license.panorama = licenceData.license.panoramaSku
            let licenceData2 = JSON.parse(JSON.stringify(licenceData1))
            licenceData2.license.sublicense = licenceData.license.sublicenseSku
            let licenceData3 = JSON.parse(JSON.stringify(licenceData2))
            licenceData3.license.panoramaSku = this.licensePanoramaSku
            let licence = JSON.parse(JSON.stringify(licenceData3))
            licence.license.sublicenseSku = this.licensesublicenseSku
            return licence
        }
        return dataToSend
    }

    private fillFormWithAPIData(dataObject: any): any {
        if (dataObject === null) { return; }
        this.isEdited = true;
        let bxLeadObj: any = {}
        for (let strKey in dataObject) {
            if (this.itemToIgnore.indexOf(strKey) == -1) {
                if (strKey === APP_CONST.PROGRAM_ADMIN) {
                    this.account_data[strKey] = this.prepareProgramAdminData(dataObject[strKey]);

                }
                else if (strKey === APP_CONST.LICENSE) {
                    this.account_data[strKey] = this.prepareLicenseData(dataObject[strKey]);
                }
                else {
                    this.account_data[strKey] = { value: dataObject[strKey], required: (this.notRequired.indexOf(strKey) < 0) }
                    if (strKey === "type" &&  this.accountData.type.value == "") {
                        this.mapSelectedAccountType();
                    }
                    else {
                        this.type = this.accountData.type.value;
                    }
                }
            } else {
                if (this.requiredKeyForProgram.indexOf(strKey) != -1) {
                    bxLeadObj[strKey] = dataObject[strKey]
                    this.account_data[APP_CONST.PROGRAMS] = this.prepareProgramValues([bxLeadObj]);
                } else {
                    this.account_data[strKey] = dataObject[strKey];
                }
            }
        }
    }

    private fillDataForEdit(dataObject: any): any {
        if (dataObject === null) { return; }
        this.isEdited = false;
        let bxLeadObj: any = {}
        for (let strKey in dataObject) {

            if (this.itemToIgnore.indexOf(strKey) == -1) {
                if (strKey === APP_CONST.PROGRAMS) {
                    this.account_data[strKey] = this.prepareProgramValues(dataObject[strKey]);
                } else if (strKey === APP_CONST.PROGRAM_ADMIN) {
                    let programAdminData = this.prepareProgramAdminData(dataObject[strKey])
                    this.account_data[strKey] = programAdminData;
                    this.phoneTypeDropdown.value = programAdminData.phoneType && programAdminData.phoneType.value ? programAdminData.phoneType.value : "Select";
                    let phone = this.phoneTypeDropdown.dropdownList.find((e: any) => this.phoneTypeDropdown.value == e.value);
                    this.phoneTypeDropdown.id = phone && phone.id;
                } else if (strKey === APP_CONST.LICENSE) {
                    let licenseData : any = this.prepareLicenseData(dataObject[strKey])
                    this.account_data[strKey] = licenseData;
                    this.panoramaDropdown.value = licenseData.panoramaSku.value;
                    this.sublicenseDropdown.value = licenseData.sublicenseSku.value;
                } else if (strKey === APP_CONST.SUBSCRIPTION) {
                    this.account_data[strKey].startDate.value = dataObject[strKey].startDate
                    this.account_data[strKey].endDate.value = dataObject[strKey].endDate
                    this.account_data[strKey].contractedScholars.value = dataObject[strKey].contractedScholars
                }
                else {
                    this.account_data[strKey] = { value: dataObject[strKey], required: (this.notRequired.indexOf(strKey) < 0) }
                    if (strKey === "type" && this.type == "" && this.accountData.type.value == "") {
                        this.mapSelectedAccountType();
                    }
                    else {
                        this.type = this.accountData.type.value;
                        if (strKey === "state"){
                            this.statesDropdown.value = this.accountData.state.value;
                            let state = this.statesDropdown.dropdownList.find((e: any) => this.statesDropdown.value == e.value);
                            this.statesDropdown.id = state && state.id;
                        }
                        else if(strKey === "type"){
                            this.accountTypeDropdown.value = this.accountData.type.value;
                            let account = this.accountTypeDropdown.dropdownList.find((e: any) => this.accountTypeDropdown.value == e.value);
                            this.accountTypeDropdown.id = account && account.id;
                        }
                    }
                }
            } else {
                if (this.requiredKeyForProgram.indexOf(strKey) != -1) {
                    bxLeadObj[strKey] = dataObject[strKey]
                    this.account_data[APP_CONST.PROGRAMS] = this.prepareProgramValues([bxLeadObj]);
                } else {
                    this.account_data[strKey] = dataObject[strKey];
                }
            }
        }
        this.initialAccountData = JSON.stringify(this.account_data)
        this.initialScheduleDate = this.account_data.programAdmin.scheduleDate.value
    }

    public created() {
        /* istanbul ignore else */
        this.getAccountTypeList();
    }

    private closeTooltip() {
        const toolTipReference: any = document.getElementById(APP_CONST.TOOL_TIP);
        toolTipReference.style.visibility = APP_CONST.HIDDEN;
    }

    private openTooltip() {
        const toolTipReference: any = document.getElementById(APP_CONST.TOOL_TIP);
        /* istanbul ignore else */
        if (!toolTipReference.style.visibility || toolTipReference.style.visibility === APP_CONST.HIDDEN || toolTipReference.style.visibility === APP_CONST.VISIBLE) {
            toolTipReference.style.visibility = toolTipReference.style.visibility == APP_CONST.VISIBLE ? APP_CONST.HIDDEN : APP_CONST.VISIBLE;
        }
    }


    public isNumericInput(event: any) {
        if (event) {
            const key = event.keyCode;
            return ((key >= APP_CONST.KEY_48 && key <= APP_CONST.KEY_57) ||
                (key >= APP_CONST.KEY_96 && key <= APP_CONST.KEY_105)
            );
        }

    }

    public isModifierKey(event: any) {
        const key = event.keyCode;
        return (event.shiftKey === true || key === APP_CONST.KEY_35 || key === APP_CONST.KEY_36) ||
            (key === APP_CONST.KEY_8 || key === APP_CONST.KEY_TAB || key === APP_CONST.KEY_ENTER || key === APP_CONST.KEY_46) ||
            (key > 36 && key < 41) ||
            (
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === APP_CONST.KEY_65 || key === APP_CONST.KEY_67 || key === APP_CONST.KEY_86 || key === APP_CONST.KEY_88 || key === APP_CONST.KEY_90)
            )
    }

    public enforceFormat(event: any) {
         /* istanbul ignore else */
        if (!this.isNumericInput(event) && !this.isModifierKey(event)) {
            event.preventDefault();
        }
    }

    public onKeyDown(event: any) {
        if (event.target.id === "zipCode") {
            if (((<any>this.accountData).zipCode.value.length <= 5) && (event.keyCode > 47 && event.keyCode < 58)) {
                return false;
            }
        }
    }

    public onKeyPress($event: any) {
        /* istanbul ignore else */
        if ($event.target.id === "zipCode" || $event.target.id === "ele_num_of_scholars") {
            if (!(($event.keyCode > 95 && $event.keyCode < 106)
                || ($event.keyCode > 47 && $event.keyCode < 58)
                || $event.keyCode == 8)) {
                $event.preventDefault();
            }
        }
    }

    public onKeyUP($event: any) {
        this.isEdited = true;
        this.isDataCanAutoSave = true;
        if ($event.target.id === "ele_program_admin_phone") {
            this.checkPhoneFormat($event);
        }
        if ($event.target.value) {
            this.buttonState = true;
        } else {
            this.buttonState = false;
        }
    }

    onlyNumber($event: any) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        /* istanbul ignore else */
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
            $event.preventDefault();
        }
    }

     /* istanbul ignore next */
    onPaste($event: any, index: number) {
        let value: any = $event.clipboardData.getData('text').replace(/[^ -~\s]/gi, '');
        let originalVal: any = value.replace(/[^0-9]/g, "");
        setTimeout(() => {
            if (value) {
                (<any>this.accountData).programs[index].numberOfScholars.value = originalVal;
            }
        }, 0);
    }



    public checkPhoneFormat(event: any) {
        if (this.isModifierKey(event)) { return; }
        let phoneInput = <HTMLInputElement>document.getElementById('ele_program_admin_phone');
        let input: any = (<any>this.accountData).programAdmin.phoneNumber.value && (<any>this.accountData).programAdmin.phoneNumber.value.replace(/\D/g, '').substring(0, 10);
        const zip = input && input.substring(0, 3);
        const middle = input && input.substring(3, 6);
        const last = input && input.substring(6, 10);

        if (phoneInput) {
            /* istanbul ignore else */
            if (input && input.length > 6) { phoneInput.value = `(${zip}) ${middle}-${last}`; }
            else if (input && input.length > 3) { phoneInput.value = `(${zip}) ${middle}`; }
            else if (input && input.length > 0) { phoneInput.value = `(${zip}`; }
        } else {
            (<any>this.accountData).programAdmin.phoneNumber.value = `(${zip}) ${middle}-${last}`;
        }

    }

    public salesforceIdValidator(salesforceId: string): boolean {
        if (/^[a-z0-9]+$/i.test(salesforceId)) {
            this.invalidSalesforceId = false;
            return true;
        } else {
            this.invalidSalesforceId = true;
        }
        return false;
    }

    backToAccountList() {
        this.invalidCity = false;
        this.$router.push('/home');
    }

    closeModal(e: { goToHome: boolean, modal: string } = { goToHome: false, modal: '' }) {
        let model = UIkit.modal(`#${e.modal}`);
        this.apiResponseCode = 0;
        this.isValidate = true;
         /* istanbul ignore if */
        if (model) {
            model.hide().then(() => {
                if (e.goToHome) {
                    model.$destroy(true);
                    this.$router.push('/home');
                }
            });
        }
    }

    getPhoneNumber(value: string) {
        let values = APP_UTILITIES.removeSpecialCharacters(value)
        return values
    }

    checkButtonState(account_data: any) {
        for (let key in account_data) {
            const checkKey = typeof account_data[key] == 'object' && account_data[key].hasOwnProperty('value') && (this.ignoreItems.indexOf(key) == -1);
            if(checkKey) {
                const value = account_data[key].value;
                /* istanbul ignore else */
                if (value) {
                    this.buttonState = true;
                    break;
                }
                else{
                    this.buttonState = false;
                    break;
                }
            } else if (typeof account_data[key] == 'object' && !account_data[key].hasOwnProperty('value')) {
                this.checkButtonState(account_data[key]);
            } else if (Array.isArray(account_data[key])) {
                this.checkButtonState(account_data[key]);
            }
        }
        return this.buttonState;
    }

    getAccountTypeList() {
        getAccountType().then((res: any) => {
            /* istanbul ignore else */
            if (res.status === APP_CONST.RESPONSE_200) {
                const data = JSON.parse(JSON.stringify(res.data));
                this.accoutTypesList = data;
                /* istanbul ignore else */
                if (this.accountData) {
                    /* istanbul ignore else */
                    if ((<any>this.accountData).type.value == "") {
                        this.mapSelectedAccountType();
                    }
                    else {
                        this.type = (<any>this.accountData).type.value;
                    }

                }
            }
        })
    }


    selectAccountType(accTypeSelected: any) {
        this.type = accTypeSelected.value;
        this.account_data.type.value = accTypeSelected.value;
        this.accountTypeDropdown.value = accTypeSelected.value;
        this.accountTypeDropdown.id = accTypeSelected.id;
    }

    selectState(state: any){
        this.accountData.state.value = state.value;
        this.statesDropdown.value = state.value;
        this.statesDropdown.id = state.id;
    }

    selectPhoneType(phone: any){
        (<any>this.accountData).programAdmin.phoneType.value = phone.value;
        this.phoneTypeDropdown.value = phone.value;
        this.phoneTypeDropdown.id = phone.id;
    }

    mapSelectedAccountType() {
        let selectedObj = this.accoutTypesList.find((e) => e.default)
        this.type = selectedObj ? selectedObj.type : "";
        if (this.account_data && this.account_data.type && this.account_data.type.value == ""){
            this.accountTypeDropdown.value = this.type;
            this.account_data.type.value = this.type ;
        }
    }

    selectListValue(type: string , listValue: any){
        (<any>this.accountData).programAdmin.phoneType.value = listValue;
        (<any>this.accountData).programAdmin.phoneType.dropStatus = false;
    }

    get previousRouteDetails(){
        return programListStore.previousRouteDetails
    }

    cancelNewAccount(){
        this.$router.push(this.previousRouteDetails.returnTo);
    }

    showAlert(){
        if(UIkit.modal('#cancel-confirmation')) {
            UIkit.modal('#cancel-confirmation').show();
        }
    }

    closeCancelPopup(){
        if(UIkit.modal('#cancel-confirmation')) {
            UIkit.modal('#cancel-confirmation').hide();
        }
    }

    showSuccessPopup(){
        if(UIkit.modal('#add-account-success')) {
            UIkit.modal('#add-account-success').show();
        }
    }

}
